<template>
    <card>
      <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.details') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="back" class="text-right"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <b-tabs content-class="mt-0" fill>
                  <b-tab :title="$t('license_management.application')" active>
                    <div v-if="loading">
                      <Loading />
                    </div>
                    <b-overlay v-else>
                      <div class="p-3">
                        <b-row>
                          <b-col sm="12">
                            <div class="text-right">
                              <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                              </b-button>
                            </div>
                            <div>
                              <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                                <div class="" style="width:50%"><b>{{ $t('license_management.agreement_no') }}</b> : {{ appDetail.application_id ? EngBangNum(appDetail.application_id) : '' }}</div>
                                <div class="text-right" style="width:50%"><b>{{ $t('externalLrcpn.issue_date') }}</b> : {{ formattedDate | dateFormat }}</div>
                              </div>
                              <div class="stepper-wrapper">
                                <div :class="item.value <= appDetail.status ? `stepper-item completed` : `stepper-item`"
                                  v-for="(item, index) in stepList" :key="index">
                                  <div class="step-counter">
                                    <i v-if="item.value <= appDetail.status" class="ri-check-fill"></i>
                                    <span v-else>{{ $n(index+1) }}</span>
                                  </div>
                                  <div class="step-name">{{ item.text }}</div>
                                </div>
                              </div>
                              <!-- Applicant info -->
                              <div class="application-form-wrapper application-form-view-wrapper mt-5">
                                <div class="application-itmes">
                                   <div class="group-form-card">
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_info')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.applicant_name')" label-for="ApplicantNameEn">
                                            <p class="input-field"> {{ currentLocale === 'en' ? appDetail.applicant_name : appDetail.applicant_name_bn }} </p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.email')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.applicant_email }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.mobile')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.applicant_mobile ? EngBangNum(appDetail.applicant_mobile) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('externalUser.telephone_no')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.telephone_number ? EngBangNum(appDetail.telephone_number) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.applicant_signature')" label-for="ApplicantNameEn">
                                              <div v-if="appDetail.applicant_signature">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="lrcpnServiceBaseUrl + appDetail.applicant_signature"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                             </div>
                                             <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.passport_no">
                                          <b-form-group :label="$t('externalUser.passport_no')" label-for="DesignationEn">
                                            <p class="input-field">{{ (appDetail.passport_no) }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.father_spouse_name')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.father_spouse_name : appDetail.father_spouse_name_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.mother_name')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.mothers_name : appDetail.mothers_name_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.nationality')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.nationality : appDetail.nationality }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- Permanent Address -->
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.permanent_address')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.area_type')" label-for="ApplicantNameEn">
                                            <p class="input-field"> {{ getAreaTypeList(appDetail.applicant_permanent_area_type_id) }} </p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.division')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getDivisionName(appDetail.applicant_permanent_division_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.district')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getDistrictList(appDetail.applicant_permanent_district_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_permanent_city_corporation_id">
                                          <b-form-group :label="$t('globalTrans.city_corporation')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getCityCorporationList(appDetail.applicant_permanent_city_corporation_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_permanent_upazila_id">
                                          <b-form-group :label="$t('globalTrans.upazila')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getUpazilaList(appDetail.applicant_permanent_upazila_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_permanent_pauroshoba_id">
                                          <b-form-group :label="$t('globalTrans.pouroshova')" label-for="DesignationEn">
                                            <p class="input-field">{{ getMunicipalityList(appDetail.applicant_permanent_pauroshoba_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_permanent_union_id">
                                          <b-form-group :label="$t('globalTrans.union')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getUnionList(appDetail.applicant_permanent_union_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.village')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.applicant_permanent_village_name : appDetail.applicant_permanent_village_name_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- Present Address -->
                                    <b-form-group label-cols-lg="3" :label="$t('globalTrans.present_address')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.area_type')" label-for="ApplicantNameEn">
                                            <p class="input-field"> {{ getAreaTypeList(appDetail.applicant_present_area_type_id) }} </p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.division')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getDivisionName(appDetail.applicant_present_division_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.district')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getDistrictList(appDetail.applicant_present_district_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_present_city_corporation_id">
                                          <b-form-group :label="$t('globalTrans.city_corporation')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getCityCorporationList(appDetail.applicant_present_city_corporation_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_present_upazila_id">
                                          <b-form-group :label="$t('globalTrans.upazila')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getUpazilaList(appDetail.applicant_present_upazila_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_present_pauroshoba_id">
                                          <b-form-group :label="$t('globalTrans.pouroshova')" label-for="DesignationEn">
                                            <p class="input-field">{{ getMunicipalityList(appDetail.applicant_present_pauroshoba_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6" v-if="appDetail.applicant_present_union_id">
                                          <b-form-group :label="$t('globalTrans.union')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ getUnionList(appDetail.applicant_present_union_id) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('globalTrans.village')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.applicant_present_village_name : appDetail.applicant_present_village_name_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- Business Address -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.business_address')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.business_address')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.business_info?.business_address : appDetail.business_info?.business_address_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.purpose_of_import')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ (appDetail.business_info?.purpose_of_acid_import) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('externalUser.telephone_no')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.business_telephone_number ? EngBangNum(appDetail.business_info?.business_telephone_number) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- Drug License Information -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.drug_license_info')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.drug_license_number')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.drug_license_info.license_no ? EngBangNum(appDetail.business_info?.drug_license_info.license_no) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.drug_license_provider')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.drug_license_info.license_provider ? (appDetail.business_info?.drug_license_info.license_provider) : ''}}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.drug_license_issue_date')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.drug_license_info.license_issue_date | dateFormat }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.drug_license_expire_date')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.drug_license_info.license_expire_date | dateFormat }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- trade_license_info -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.trade_license_info')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.trade_license_number')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.trade_license_info?.license_no ? EngBangNum(appDetail.business_info?.trade_license_info.license_no) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.trade_license_type')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ (appDetail.business_info?.trade_license_info?.license_type) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.trade_license_provider')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ (appDetail.business_info?.trade_license_info?.license_provider) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.trade_license_issue_place')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ (appDetail.business_info?.trade_license_info?.license_issue_address) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.trade_license_expire_date')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.trade_license_info?.license_expire_date | dateFormat }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- tax_info -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.tax_information')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('externalUser.tin_no')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.tax_info?.tin_no ? EngBangNum(appDetail.business_info?.tax_info?.tin_no) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.circle')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ (appDetail.business_info?.tax_info?.circle) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.zone')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ (appDetail.business_info?.tax_info?.zone) }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.last_two_year_tax_paid_amount')" label-for="ApplicantNameEn">
                                            <div v-if="appDetail.business_info">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="lrcpnServiceBaseUrl + appDetail.business_info?.tax_info?.last_two_years_tax_attachment"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                             </div>
                                             <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- warehouse_info -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.warehouse_info')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.warehouse_address')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.business_info?.warehouse_info.address : appDetail.business_info?.warehouse_info.address_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.warehouse_details')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ currentLocale === 'en' ? appDetail.business_info?.warehouse_info.description : appDetail.business_info?.warehouse_info.description_bn }}</p>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.warehouse_capacity')" label-for="ApplicantNameEn">
                                            <p class="input-field">{{ appDetail.business_info?.warehouse_info.capacity ? EngBangNum(appDetail.business_info?.warehouse_info.capacity) : '' }}</p>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                                    <!-- attachment_info -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.attachment_info')" label-size="md"
                                      label-class="font-weight-bold pt-0" class="form-view-item">
                                      <b-row>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.imported_acid_name_details_attachment')" label-for="ApplicantNameEn">
                                            <div v-if="appDetail.applicant_signature">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="lrcpnServiceBaseUrl + appDetail.business_info?.imported_acid_name_details_attachment"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                             </div>
                                             <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.bank_lc_details')" label-for="ApplicantNameEn">
                                            <div v-if="appDetail.applicant_signature">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="lrcpnServiceBaseUrl + appDetail.business_info?.lc_bank_loan_details_for_import_attachment"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                             </div>
                                             <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="6">
                                          <b-form-group :label="$t('lrcpnServicePanel.recently_imported_acid_info_detail_attachment')" label-for="ApplicantNameEn">
                                            <div v-if="appDetail.applicant_signature">
                                                <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                                <a target="_blank"
                                                  :href="lrcpnServiceBaseUrl + appDetail.business_info?.recently_imported_acid_info_detail_attachment"><i
                                                    style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                             </div>
                                             <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <br>
                            <!-- Import information -->
                            <div class="application-form-wrapper application-form-view-wrapper mt-5">
                              <div class="application-itmes">
                                 <div class="group-form-card">
                                  <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.acid_import_info')" label-size="md"
                                    label-class="font-weight-bold pt-0" class="form-view-item">
                                    <b-row>
                                      <b-overlay :show="addMoreLoading">
                                            <b-table-simple style="width: 100%" bordered>
                                                <b-tr>
                                                    <b-th class="text-center" style="width: 50%;"> {{ $t('lrcpnServicePanel.acid_name') }}</b-th>
                                                    <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.qty') }}</b-th>
                                                    <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.price') }}</b-th>
                                                </b-tr>
                                                <template v-if="appDetail.business_info?.acid_import_worth_value_infos?.length">
                                                  <slot v-for="(item, index) in appDetail.business_info?.acid_import_worth_value_infos">
                                                        <b-tr :key="index">
                                                            <b-td class="text-center">
                                                                {{ getAcidName(item.acid_id) }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ $n(item.qty) }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ $n(item.price) }}
                                                            </b-td>
                                                        </b-tr>
                                                    </slot>
                                                </template>
                                                <template v-else>
                                                    <b-tr>
                                                        <b-td colspan="3" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                                    </b-tr>
                                                </template>
                                            </b-table-simple>
                                        </b-overlay>
                                    </b-row>
                                  </b-form-group>
                                    <!-- import_details -->
                                    <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.import_details')" label-size="md"
                                              label-class="font-weight-bold pt-0" class="form-view-item">
                                              <b-row>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.import_purpose')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ appDetail.business_info?.present_import_info?.purpose }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.import_route')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ appDetail.business_info?.present_import_info?.route }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.import_port_name')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ (appDetail.business_info?.present_import_info?.access) }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.estimated_date_of_import')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ appDetail.business_info?.present_import_info?.possible_time | dateFormat }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.have_previous_license')" label-for="ApplicantNameEn">
                                                    <p class="input-field" v-if="(appDetail.business_info?.previous_license_info.have_previous_license) === 1">{{ currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' }}</p>
                                                    <p class="input-field" v-else>{{ currentLocale === 'en' ? 'No' : 'না' }}</p>
                                                    <!-- <p class="input-field">{{ currentLocale === 'en' ? (appDetail.business_info?.previous_license_info.have_previous_license) === '1' ? Yes : No : (appDetail.business_info?.previous_license_info.have_previous_license) !== '1' ? হ্যাঁ : না }}</p> -->
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6" v-if="(appDetail.business_info?.previous_license_info.have_previous_license) === 1">
                                                  <b-form-group :label="$t('lrcpnServicePanel.previous_license_number')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ EngBangNum(appDetail.business_info?.previous_license_info.previous_acid_import_license_no) }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6" v-if="(appDetail.business_info?.previous_license_info.have_previous_license) === 1">
                                                  <b-form-group :label="$t('lrcpnServicePanel.previous_license_provide_date')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ appDetail.business_info?.previous_license_info.previous_acid_import_license_issue_date | dateFormat }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.has_previous_license_rejected')" label-for="ApplicantNameEn">
                                                    <p class="input-field" v-if="(appDetail.business_info?.is_previous_license_reject) === 1">{{ currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' }}</p>
                                                    <p class="input-field" v-else>{{ currentLocale === 'en' ? 'No' : 'না' }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6" v-if="(appDetail.business_info?.is_previous_license_reject) === 1">
                                                  <b-form-group :label="$t('lrcpnServicePanel.rejected_reasons')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ (appDetail.business_info?.previous_license_reject_reasons) }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6"  v-if="appDetail.business_info?.import_agent_name">
                                                  <b-form-group :label="$t('lrcpnServicePanel.import_helping_agent_name')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ (appDetail.business_info?.import_agent_name) }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6"  v-if="appDetail.business_info?.import_agent_address">
                                                  <b-form-group :label="$t('lrcpnServicePanel.import_helping_agent_address')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ (appDetail.business_info?.import_agent_address) }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group :label="$t('lrcpnServicePanel.any_case_field_under_acis_control_act')" label-for="ApplicantNameEn">
                                                    <p class="input-field" v-if="(appDetail.business_info?.any_case_field_under_acis_control_act.has_case) === 1">{{ currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' }}</p>
                                                    <p class="input-field" v-else>{{ currentLocale === 'en' ? 'No' : 'না' }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6"  v-if="appDetail.business_info?.any_case_field_under_acis_control_act">
                                                  <b-form-group :label="$t('lrcpnServicePanel.case_no_and_section')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ (appDetail.business_info?.any_case_field_under_acis_control_act.case_number_section) }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6"  v-if="appDetail.business_info?.any_case_field_under_acis_control_act">
                                                  <b-form-group :label="$t('lrcpnServicePanel.date_of_case_judgment')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ appDetail.business_info?.any_case_field_under_acis_control_act.date_of_sentencing | dateFormat }}</p>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col sm="6" v-if="appDetail.business_info?.any_case_field_under_acis_control_act">
                                                  <b-form-group :label="$t('lrcpnServicePanel.date_of_completion_of_sentence')" label-for="ApplicantNameEn">
                                                    <p class="input-field">{{ appDetail.business_info?.any_case_field_under_acis_control_act.date_of_completion | dateFormat }}</p>
                                                  </b-form-group>
                                                </b-col>
                                              </b-row>
                                            </b-form-group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-overlay>
                  </b-tab>
                  <b-tab v-if="appDetail.forwards && $store.state.Auth.authUser.user_type === 1" :title="$t('teaGardenPanel.forward_history')">
                  <div v-if="forwarLoad || loading" class="col-md-12">
                    <forward-loading></forward-loading>
                  </div>
                  <div v-else  class="row">
                    <div class="col-md-10 offset-md-1">
                      <div v-for="(forward, index) in appDetail.forwards" :key="index">
                        <b-card>
                          <b-card-title> <h5 style="font-size:17px">{{ getCardTitle(forward.step_id) }}</h5> </b-card-title>
                          <b-card-sub-title style="font-size:14px">
                            <span class="badge badge-success">{{ $t('globalTrans.sender') }}</span> : {{ getUserName(forward.sender_id) }}  <i class=" ml-3 ri-arrow-right-line"></i>
                            <span class="badge badge-primary ml-3">{{ $t('globalTrans.receiver') }}</span> : {{ getUserName(forward.receiver_id) }} <span class="badge badge-info">{{ getDesignationName(forward.designation_id)}}</span>
                          </b-card-sub-title>
                          <b-card-text class="mt-3">
                            <small class="mt-4">
                            <b>{{ $t('globalTrans.date') }}</b> : {{ forward.created_at | dateFormatTime }}
                          </small>
                          <br>
                            <b>{{ $t('globalTrans.note') }}</b> : <span>{{ currentLocale === 'en' ? forward.note_en : forward.note_bn }}</span>
                          </b-card-text>
                          <p class="text-right" v-if="forward.attachment"><b>{{$t('globalTrans.attachment')}}</b>
                                <a target="_blank" :href="lrcpnServiceBaseUrl + (isImage(forward.attachment) ? 'storage/' : '') + forward.attachment"><i style="font-weight:bold" class="ri-download-line download-btn"></i>
                                </a>
                          </p>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </b-tab>
                </b-tabs>
              </b-col>
              <b-col md="12" class="text-right mt-2">
                <b-button @click="back"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
              </b-col>
            </b-row>
          </template>
      </body-card>
    </card>
  </template>
  <script>
  import RestApi, { lrcpnServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
  import { acidImportClearanceApplicationShowApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import ForwardLoading from './loading/ForwardLoading.vue'
  import Loading from './loading/Details.vue'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Loading,
      ForwardLoading
    },
    data () {
      return {
        lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          company_name: '',
          limit: 10
        },
        appDetail: {
          created_at: null
        },
        items: [],
        // listData: [],
        profile: this.$store.state.ExternalUserService.eBizPanel.associationProfile,
        associationInfoList: [],
        warehouseList: [],
        viewitemId: 0,
        users: [],
        forwarLoad: false
      }
    },
    created () {
      this.loadData()
    },
    watch: {
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
          return this.$i18n.locale
        },
        formattedDate () {
          return this.appDetail.created_at.slice(0, 10)
        },
        typeOfBusinessList () {
          return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
        },
        appId () {
          return this.$route.params.id
        },
        masterAcidNameList () {
          if (this.$store.state.Auth.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
          } else {
            return this.$store.state.LrcpnService.commonObj.masterAcidNameList.filter(item => item.status === 1)
          }
        },
        divisionList () {
            return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        districtList () {
            return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
        },
        cityCorporationList () {
            return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1)
        },
        upazilaList () {
            return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1)
        },
        municipalityList () {
            return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1)
        },
        unionList () {
            return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1)
        },
        areaTypeList () {
            return this.$store.state.commonObj.areaTypeList
        },
        stepList () {
          const setp = [
            { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
            { value: 4, text: this.$i18n.locale === 'en' ? 'Inspection Pending' : 'পরিদর্শন প্রক্রিয়াধীন', text_en: 'Inspection Pending', text_bn: 'পরিদর্শন প্রক্রিয়াধীন' },
            { value: 5, text: this.$i18n.locale === 'en' ? 'Inspection Complete' : 'পরিদর্শন সম্পন্ন', text_en: 'Inspection Complete', text_bn: 'পরিদর্শন সম্পন্ন' },
            { value: 6, text: this.$i18n.locale === 'en' ? 'Approval' : 'অনুমোদন', text_en: 'Approval', text_bn: 'অনুমোদন' },
            { value: 7, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
            { value: 8, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
          ]
          if (this.appDetail.status === 8) {
            return setp.filter(item => item.value !== 7)
          } else {
            return setp.filter(item => item.value !== 8)
          }
        }
      },
    methods: {
      async getForwardedUsers (forwards) {
        this.forwarLoad = true
        var userIds = []
        forwards.map((item, key) => {
          userIds.push(item.sender_id)
          userIds.push(item.receiver_id)
        })
        const params = Object.assign({}, { user_ids: userIds })
        await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
            if (response.length) {
              this.users = response
            } else {
              this.users = []
            }
            this.forwarLoad = false
        })
      },
      getUserName (userId) {
        const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
        return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
      },
      getTypeOfBussiness (businessId) {
            const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1).find(item => item.value === businessId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
      },
      getDesignationName (id) {
        const obj = this.$store.state.CommonService.commonObj.designationList.find(item => parseInt(item.value) === parseInt(id))
        if (obj) {
          return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        }
        return ''
      },
      getCardTitle (id) {
        const toStep = this.$store.state.LrcpnService.commonObj.acidLicenseStepList.find(item => item.value === parseInt(id))
        if (typeof toStep !== 'undefined') {
          return toStep.text
        } else {
          return ''
        }
      },
      EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
      },
      getCouncilName (councilNameId) {
            const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.councilInfoList.filter(item => item.status === 1).find(item => item.value === councilNameId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
      },
      getAssociationName (associationId) {
          const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.associationInfoList.filter(item => item.status === 1).find(item => item.value === associationId)
          if (cateObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return cateObj.text_bn
              } else {
                  return cateObj.text_en
              }
          }
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(lrcpnServiceBaseUrl, acidImportClearanceApplicationShowApi + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
          if (this.appDetail.forwards.length) {
            this.getForwardedUsers(result.data.forwards)
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      },
      async pdfExport () {
        this.loading = true
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 1, app_id: this.appId })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(lrcpnServiceBaseUrl, acidImportClearanceApplicationShowApi + '/' + this.appId, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        this.loading = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getAcidName (id) {
          const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getAreaTypeList (id) {
          const obj = this.areaTypeList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDivisionName (id) {
          const obj = this.divisionList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDistrictList (id) {
          const obj = this.districtList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getCityCorporationList (id) {
          const obj = this.cityCorporationList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getUpazilaList (id) {
          const obj = this.upazilaList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getMunicipalityList (id) {
          const obj = this.municipalityList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getUnionList (id) {
          const obj = this.unionList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      back () {
        this.$router.go(-1)
      },
      isImage (path) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
      }
    }
  }
  </script>
  <style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    @media (max-width: 768px) {
        font-size: 12px;
    }
    }
    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }
    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }
    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }
    .stepper-item.active {
    font-weight: bold;
    }
    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }
    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }
    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
  .tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
  }
  .tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
  }
  </style>
